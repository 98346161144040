import React, { useState, useEffect } from "react";

// Bootstrap imports
import { Container, Col, Row, Dropdown, DropdownButton } from "react-bootstrap";

// Component imports
import MarchMadnessBracket from "../components/featured/Brackets";
import { rounds } from "../constants/brackets";

// Styling
import "../components/home/home.css";

export default function Featured() {
  useEffect(() => {
    document.title = "WSA";
    const mediaQuery = window.matchMedia('(max-width: 1000px)');
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (e) => setIsMobile(e.matches);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [year, setYear] = useState("2023");
  const [region, setRegion] = useState("South");
  const [yearPercent, setYearPercent] = useState(0.0);
  const [regionPercent, setRegionPercent] = useState(0.0);

  const handleYearSelect = (event) => {
    setYear(event);
  }

  const handleRegionSelect = (event) => {
    setRegion(event);
  }

  //calculate percent correct
  useEffect(() => {
    let wrongPredictionsCount = 0;
    let rightPredictionsCount = 1; //assuming all "first four" predictions are right
    Object.values(rounds[year][region]).map(round => {
        round.seeds.map(seed => {
            seed.teams.map(team => {
                if (round.title != "First Round") {
                    team.prediction === false ? wrongPredictionsCount++ : rightPredictionsCount++;
                }
                else { //to keep account for "first four" predictions (only in First Round)
                    if (team.prediction === false) {
                        rightPredictionsCount--; //one less prediction is right
                        wrongPredictionsCount++; //one more prediction is wrong
                    }
                }
            });
        });
    });
    if (region == "Nationals") { //compensate if no First Round
        rightPredictionsCount--;
    }
    const percent = rightPredictionsCount / (wrongPredictionsCount + rightPredictionsCount) * 100;
    setRegionPercent(percent.toFixed(1))
  }, [region]);

  useEffect(() => {
    let wrongPredictionsCount = 0;
    let rightPredictionsCount = 4;
    Object.keys(rounds[year]).map(region_key => {
        Object.values(rounds[year][region_key]).map(round => {
            round.seeds.map(seed => {
                seed.teams.map(team => {
                    if (round.title != "First Round") {
                        team.prediction === false ? wrongPredictionsCount++ : rightPredictionsCount++;
                    }
                    else { //to keep account for "first four" predictions (only in First Round)
                        if (team.prediction === false) {
                            rightPredictionsCount--; //one less prediction is right
                            wrongPredictionsCount++; //one more prediction is wrong
                        }
                    }
                });
            });
        });
    })
    const percent = rightPredictionsCount / (wrongPredictionsCount + rightPredictionsCount) * 100;
    setYearPercent(percent.toFixed(1))
  }, [year]);

  return (
    <div className="home-page">
      <div className="bracket-background" style={{marginBottom: '20px'}}>
        <Container className="main">
            <Row>
                <div className="projects-header">
                    <h1>Featured Project</h1>
                    <h3>March Madness Bracket Predictor</h3>
                    <h6>Choose a year and region to see how our predictions did! Red means incorrect prediction.</h6>
                </div>
            </Row>
            <Row>
                <Col xs={12} md={2}>
                <h4>Selector</h4>
                <Container className="bracket-selector">
                    <h5>Year:</h5>
                    <Dropdown>
                        <DropdownButton
                            title={year}
                            onSelect={handleYearSelect}
                        >
                            <Dropdown.Item eventKey={"2023"}>2023</Dropdown.Item>
                        </DropdownButton>
                    </Dropdown>
                    <hr></hr>
                    <h5>Region:</h5>
                    <Dropdown>
                        <DropdownButton
                            title={region}
                            onSelect={handleRegionSelect}
                        >
                            <Dropdown.Item eventKey={"South"}>South</Dropdown.Item>
                            <Dropdown.Item eventKey={"East"}>East</Dropdown.Item>
                            <Dropdown.Item eventKey={"Midwest"}>Midwest</Dropdown.Item>
                            <Dropdown.Item eventKey={"West"}>West</Dropdown.Item>
                            <Dropdown.Item eventKey={"Nationals"}>Nationals</Dropdown.Item>
                        </DropdownButton>
                    </Dropdown>
                </Container>
                <h4>Predictions</h4>
                <Container className="bracket-selector">
                    <h5>% Correct for Selected Year: <span style={{color: '#007bff'}}>{yearPercent}%</span></h5>
                    <h5>% Correct for Selected Region: <span style={{color: '#007bff'}}>{regionPercent}%</span></h5>
                </Container>
                {isMobile && 
                <>
                    <h5>Swipe left or right to access other rounds.</h5>
                    <br></br>
                </>}
                </Col>
                <Col xs={12} md={6}>
                    <MarchMadnessBracket year={year} region={region}/>
                </Col>
            </Row>
        </Container>
      </div>
    </div>
  );
}
