import React, { useState, useEffect } from "react";

// split text into chunks of 100 words -> array
// map out text chunks along with images until they run out

export default function ProjectPage({ title, authors, text, images }) {
  const [chunks, setChunks] = useState([]);

  function splitTextIntoArray(textToBeSplit, numChunks) {
    let tempChunks = [];
  
    // Split text to get word count and determine chunk size
    const splitText = textToBeSplit.toString().split(" ");
    const wordCount = splitText.length;
    const chunkSize = wordCount / numChunks + 1;
  
    let start = 0;
    while (start < wordCount) {
      tempChunks.push(splitText.slice(start, chunkSize + start).join(" "));
      start += chunkSize;
    }
  
    return tempChunks;
  }

  function createTextChunks() {
    const numChunks = images.length;
    let tempChunks = [];
    // If there are no images, the entire text is the only chunk
    if (numChunks === 0) {
      tempChunks.push({ textChunk: text, picture: null, caption: null });
    } else {
      // Split text into numChunks chunks
      const splitText = splitTextIntoArray(text, numChunks);
      // Create text, images pairs and add into tempChunks
      images.forEach((image, i) => {
        tempChunks.push({
          textChunk: splitText[i],
          picture: image.image,
          caption: image.caption,
        });
      });
    }
    setChunks(tempChunks);
  }

  useEffect(() => {
    document.title = title;
    createTextChunks();
  }, []);

  return (
    <div className="project-page">
      <h1>{title}</h1>
      { authors.length > 0 &&
        <h5>Contributors:&nbsp;
            <div style={{ display: 'inline-block' }}>
                {authors.map((author, index) => (
                    <span key={index}>{author}{index < authors.length - 1 ? ', ' : ''}</span>
                ))}
            </div>
        </h5>
      }
      {chunks.map((chunk, i) => {
        const { textChunk, picture, caption } = chunk;
        return (
          <div key={i}>
            {picture && (
              <aside className={i % 2 === 0 ? "aside-right" : "aside-left"}>
                <figure>
                  <img src={picture} style={{maxWidth: "600px", width: "100%", maxHeight: "300px", height: "100%"}} 
                    alt={caption} loading="lazy" />
                  <figcaption>{caption}</figcaption>
                </figure>
              </aside>
            )}
            <p>{textChunk}</p>
          </div>
        );
      })}
    </div>
  );
}
