import image from "../../../images/football/washington-sp.png"
import image1 from "../../../images/football/michigan-sp.png"
import {citation, video} from "../helper";

export default function RoseBowl() {

  return (
    <div>
      <div>
        There is no question which team analytics favor in the final National Championship game 
        of the 4-team College Football Playoff era. FEI, an opponent-adjusted scoring-based 
        metric, expects Michigan to win by 8.2 points
        {citation(1, "https://www.bcftoys.com/2023-gp/")}
        . SP+, a predictive model based on teams’ 
        offensive and defensive efficiency, spots the Wolverine’s margin of victory at 12.3
        {citation(2, "https://archive.is/wip/8zpyi")}
        . ESPN’s FPI has Michigan winning a whopping 75% of the time.
        {citation(3, "https://www.espn.com/college-football/game/_/gameId/401551789")}
      </div>
      <br />
      <div>
        Washington, however, has proven to care very little about analytics’ preferred outcome. 
        The Huskies were given just a 45%, 36%, and 31%
        {citation(4, "https://www.sportinglad.com/american-sports/sugar-bowl-prediction/")}
        chance from FEI, SP+, and FPI, respectively, to beat Texas and arrive in the championship game. 
        They earned their spot in that semifinal by conquering a PAC-12 championship game that FEI 
        gave them just a 22% chance of winning. All three of FEI, SP+, and FPI still rank the Oregon 
        team Washington beat in that Championship game (for the second time this season) above them. 
        If the analytics had their way, Washington would not be here. 
      </div>
      <br />
      <div>
        Instead, Washington has treated its efficiency ratings with the same defiance it has afforded 
        its opponents. The Huskies have won each of their last ten games by 10 points or fewer, 
        the longest such streak in Division I history
        {citation(5, "https://www.foxsports.com/stories/college-football/michigan-vs-washington-cfp-national-championship-by-the-numbers")}
        . They survived a fourth-quarter drive in the Sugar Bowl that saw Texas arrive at Washington’s 
        own 12 yard line with 15 seconds left on the clock, barely further than a first down away 
        from stealing the game
        {citation(6, "https://youtu.be/E7jghDC-paI?si=p8oe8zIFrpOOGO-M&t=188")}
        . They won the final PAC-12 championship game despite trailing entering the fourth quarter
        {citation(7, "https://www.espn.com/college-football/game/_/gameId/401539475/ducks-huskies")}
        . They beat rival Washington State in the Apple Cup after converting on 4th & 1 at their own 
        29 yard line with the game tied and just a minute left on the clock
        {citation(8, "https://youtu.be/By7cVJvXEhA?si=z0LKe35p4dg7B29C&t=458")}
        . If this blog were in the business of destiny instead of analytics, this post would be a 
        definitive prediction of a Washington victory, perhaps on a goal line stand, or a game-ending Hail Mary.
      </div>
      <br />
      <div>
        The story the analytics tell, though, is equally compelling. Much attention has been given 
        to the battle between Michigan’s defense, the best or second best in the country according 
        to virtually all predictive metrics, and Washington’s offense, the fourth or fifth best in 
        the country by most metrics, that bursts with explosive plays off the arm of Heisman runner-up 
        Michael Penix Jr. into the hands of a spread of talented receiving options. Michigan’s defense 
        leads the league in opponent points per game 
        {citation(9, "https://www.teamrankings.com/college-football/stat/opponent-points-per-game")}
        and is second in opponent passing yards per game
        {citation(10, "https://www.teamrankings.com/college-football/stat/opponent-passing-yards-per-game")}
        , sure, but have they been tested by an offense as talented as Washington’s, where Penix is 
        coming off a 430 yard passing performance in the Sugar Bowl and WR Rome Odunze is seen by many 
        as the best in the league? And Washington’s passing attack leads the league in yards per game
        {citation(11, "https://www.teamrankings.com/college-football/stat/passing-yards-per-game")}
        , yes, but is that just the result of playing less imposing Pac-12 defenses? 
      </div>
      <br />
      <div>
        The other side of the ball, however, is just as fascinating. Washington has scraped through 
        the season undefeated despite a rush defense that is among the worst in the FBS. Their 
        opponents’ rushing success rate of 47.5% is eighth worst in the league, and their allowed 
        EPA/rush of 0.13 (15th worst in the league)
        {citation(12, "https://gameonpaper.com/cfb/year/2023/team/264")}
        indicates their opponents are adding over a tenth of a point, on average, to their expected 
        point totals
        {citation(13, "https://www.oddsshopper.com/articles/betting-101/nfl-football-betting-guide-what-are-expected-points-added-epa-y10")}
        every time they run the ball. 
      </div>
      <br />
      <div>
        How have they overcome this shortcoming? Despite playing in the fast-paced PAC-12 and defending 
        70 snaps a game, the 19th highest mark in football, the Huskies are one of the least run-against 
        teams there is, with just 29 rushes against them per game (the 26th fewest in the league)
        {citation(14, "https://gameonpaper.com/cfb/year/2023/team/264")}
        . The problem for Washington? Michigan is among the run-heaviest teams in the country, running 
        the ball on 59.12% of plays
        {citation(15, "https://www.teamrankings.com/college-football/stat/rushing-play-pct")}
        , compared to the just 41.86% of plays on which opponents have run against Washington so far this season. 
      </div>
      <br />
      <div>
        But Michigan has problems of its own. Despite their penchant for the ground game, Michigan has 
        rushed at an average level, with a barely positive .01 EPA/rush clocking in at 71st overall
        {citation(16, "https://gameonpaper.com/cfb/year/2023/team/130")}
        , in the bottom half of FBS offenses. Plus, Washington has played air-based offenses all 
        season, and their run defense may improve when their primary goal is stopping the run, not the pass.
      </div>
      <br />
      <div>
        Schematically, then, the National Championship will be a game between two teams with opposing play 
        styles adjusting to offensive schemes that differ from what each is used to defending. Though the 
        styles are different, the strength of the opponents each team has played gives some insight into 
        what to expect Monday night. Michigan is the fourth best offense Washington has faced by pre-bowl 
        game SP+ rating
        {citation(17, "#", [`I use pre-bowl ratings since SP+ doesn’t adjust for opt-outs, so its post-bowl 
        rankings can be skewed by blowouts that would not have happened in the regular season (as an example: 
        Georgia rose to first overall following its 63-3 drubbing of a Florida State team playing with 20+ scholarship players sitting out)`])}
        , while Washington is the ninth best defense Michigan has faced. 
      </div>
      <img src={image} alt="Washington Opponents' Points by Offensive SP+" loading="lazy" className="post-image" />
      <img src={image1} alt="Michigan Opponents' Points by Offensive SP+" loading="lazy" className="post-image" />
      <br />
      <div>
        Against Texas, whose offensive SP+ of 38.3 sits one spot above Michigan’s 38.1, Washington gave up 
        31 points, on par with the projection given by a simple least-squares regression. Michigan posted a 
        point total of 52 against Minnesota, its closest defensive opponent to Washington (defensive SP+ of 
        23.2 for the Gophers vs 23.7 for the Huskies), though a regression pins their expected point total 
        against that a defense of Washington’s strength at a more reasonable 37.8 points. Both numbers indicate 
        Michigan has an opportunity for a dominant offensive performance, if they can take advantage of a 
        Washington defense that is the weakest they have faced since the opening week of November.
      </div>
      <h4><b>Pass Offense</b></h4>
      <div>
        The Washington defense operates almost exclusively out of a 4-2-5 scheme. In their games against Texas 
        and Oregon, the Huskies would sometimes line up with more than four players threatening to pass rush 
        (usually on 2nd and 3rd & long) but tended to drop the extra players back into coverage and still rush 
        just four. When they wanted to get after the quarterback, Washington would tend toward dialing up 
        stunts or dropping a DL into coverage while bringing an LB or DB in their stead, as they did in this 
        play against Oregon, where EDGE Sekai Asoau-Afoa (#46, bottom-most DE) drops into coverage and MLB 
        Edefuan Ulofoshio (#5) rushes into a different gap instead.
      </div>
      <br />
      {video("https://www.youtube.com/embed/6exNu140P5I?start=1238&end=1259&autoplay=1")}
      <div>
        Washington’s biggest strength in pass defense, though, is their ability to generate pressure on the 
        quarterback without rushing extra defenders. Despite having the fourth lowest sack percentage of any 
        FBS team
        {citation(18, "https://www.teamrankings.com/college-football/stat/sack-pct")}
        , the Huskies are frequently able to hurry opposing quarterbacks by quickly collapsing the pocket 
        around them. This is largely due to edge rusher Bralen Trice, the only projected first round pick
        {citation(19, "https://www.nfldraftbuzz.com/Player/Bralen-Trice-LB-Washington")}
        on the defense, who leads the country in QB pressures with 77 over 14 games despite having just 7 
        sacks to his name
        {citation(20, "https://apnews.com/article/washington-trice-cfp-huskies-903f98395fe8af463a2a2e0b4b58171d#:~:text=Trice%20had%20just%20five%20sacks,another%20seven%20against%20the%20Longhorns")}
        . Trice achieves this with brute strength, overpowering OLs trying to block him. 
      </div>
      {video("https://www.youtube.com/embed/6exNu140P5I?start=2695&end=2705&autoplay=1")}
      <div>
        ESPN’s Kirk Herbstreit rightfully marvels at Trice’s pass-rush on this play, as he mows through 
        Oregon’s Josh Conerly Jr (#76, left side of the line), sending him back five steps into his own 
        quarterback. Trice picks on Conerly again later on the same drive, sending him back five yards 
        before shaking him and chasing Oregon quarterback Bo Nix toward the sideline.
      </div>
      {video("https://www.youtube.com/embed/6exNu140P5I?start=2943&end=2983&autoplay=1")}
      <div>
        Trice is aided on the other side of the line by his copart Tupuola-Fetui, whose season statline 
        of 19 pressures, including 5 sacks
        {citation(21, "https://archive.is/9cndz")}
        , is a few rungs below Trice’s, but respectable nonetheless.
      </div>
      <br />
      <div>
        Washington’s ability to pressure Texas QB Quinn Ewers, including two sacks to Trice’s name
        {citation(22, "https://www.espn.com/college-football/boxscore/_/gameId/401551788")}
        , was largely the difference between Texas’ successful and unsuccessful drives. The consistent 
        theme uniting Texas’ five drives that ended in punts was Ewers coming under pressure. 
        The Longhorns’ opening drive featured back-to-back incompletions on 1st and 2nd & 10, one a rushed 
        throw because of pressure in Ewers’ face and the other a ball batted at the line of scrimmage
        {citation(23, "#", "Unfortunately I couldn’t find free video of either of these plays on youtube, so I can’t link them.")}
        , leading to a punt after another incompletion on 3rd & 10. Their second punt came on a set of downs 
        that began with Trice sacking Ewers to set up a 2nd & 16, putting Texas well behind the sticks. 
        Their third punt was set up by a 3rd & 12 that was the result of impending offensive linemen causing 
        Ewers to dump the ball off to his running back, who got tackled behind the line of scrimmage for a 
        loss of two, and Texas’ final punt of the game came when Washington brought six rushers and Trice 
        got through unblocked, forcing Ewers to throw it away on 3rd & 5.
      </div>
      {video("https://www.youtube.com/embed/gS7WczOL5YQ?start=697&end=715&autoplay=1")}
      <div>
        A weakness of the Huskies’ pass defense, though, is their DBs’ ability to follow receivers improvising 
        once pressure arrives. A good example from their early season matchup against Oregon was the same play 
        Trice forced Nix to roll out in the red zone, clipped above.
      </div>
      {video("https://www.youtube.com/embed/Kjv3RYsWnzQ?start=246&end=277&autoplay=1")}
      <div>
        Though the pass rush succeeded, and Nix is flushed from the pocket, Oregon TE Patrick Herbert is able 
        to run back across the field completely uncovered, as both zone defenders in Herbert’s area trail off 
        and are too late to react to the play unfolding. This also led to some big plays for Texas in the Sugar 
        Bowl, including this pass to WR Ja’Tavion Sanders late in the game.
      </div>
      {video("https://www.youtube.com/embed/gS7WczOL5YQ?start=993&end=1006&autoplay=1")}
      <div>
        Ewers is forced to hold the ball for a long time on this play, as coverage is tight downfield, but 
        when he rolls out to his left and receivers start improvising, Sanders is able to get a step on 
        Washington safety Dominique Hampton
        {citation(24, "#", "This is clearer on the All-22 angle on ESPN’s website, but unfortunately that angle isn’t on YouTube.")}
        , and the pass is completed for a solid gain.
      </div>
      <br />
      <div>
        In the secondary, Washington’s defense is notable for its relative dearth of weapons. CB Jabbar 
        Muhammad had a strong game in the Sugar Bowl, and has been reliable throughout the year, but is 
        rated as a middle-tier defensive back
        {citation(25, "https://www.nfldraftbuzz.com/Player/Jabbar-Muhammad-CB-OklahomaState")}
        . No other players stand out as stars or playmakers; the group’s strength lies in its rote consistency, 
        not its flashiness.
      </div>
      <br />
      <div>
        Their pass defense doesn’t rely on the NFL-style switches and changeups of Michigan or the complicated 
        pattern-matching of Alabama to confuse or overwhelm opposing quarterbacks. Washington only has a few 
        packages at each level of defense, but their ability to mix-and-match these packages and deploy them all 
        no matter their pre-snap look can pose a challenge for passers trying to get a read on the coverage. 
      </div>
      <br />
      <div>
        The Huskies play the majority of their snaps out of cover 2, but they can supplement this with cover 
        3 or with a “rotated” cover 2, where both zone defenders are between the hash marks, one playing deep 
        and one playing shallow. This rotated look could be problematic for Michigan QB J.J. McCarthy, who 
        frequently throws over the middle of the field, especially to his tight ends.
      </div>
      <br />
      <div>
        Defenders sitting in the middle of the field has caused trouble for Washington’s opposing quarterbacks 
        throughout the season. In its game against California, the Huskies came away with three interceptions, 
        including a pick-six just minutes into the game, when Cal QB Ben Finley failed to notice Ulofoshio 
        settling back into a zone just behind the line of scrimmage, right in the path of his intended receiver.
      </div>
      {video("https://www.youtube.com/embed/_oqTo8QRXJE?start=23&end=25&autoplay=1")}
      <div>
        Beneath the safeties, Washington has three main looks: straight man, straight zone, or man on one side 
        of the field and zone on the other. Their pre-snap alignment looks identical for all three, usually 
        with two linebackers over the middle and defensive backs aligned with receivers and playing five or 
        six yards off the line of scrimmage. Washington rarely reveals man coverage before the snap; in the 
        Sugar Bowl, a Washington defender only followed a Texas player in motion across the formation once – 
        all other times Washington simply switched assignments, a tendency Michigan could use to match stronger 
        receivers against weaker defensive backs.
      </div>
      <br />
      <div>
        One tactic Washington deployed consistently was assigning a man defender to running backs aligned in 
        the backfield. The defender would either follow the running back out if they left on a route or join 
        the pass-rush if they stayed to block. Usually, this role fell at the hands of LB Ulofoshio, as in this 
        play, where you can see him hovering over RB CJ Baxter at the top of the screen.
      </div>
      {video("https://www.youtube.com/embed/gS7WczOL5YQ?start=221&end=280&autoplay=1")}
      <div>
        This defender posed some problems for both Oregon and Texas when they would attempt bubble screens 
        to their running backs. Though this example falls incomplete because of pressure on the quarterback, 
        WLB Alphonzo Tuputala (#11) is shadowing Oregon RB Bucky Irving the entire play, and sprints in ready 
        to make a tackle for a loss had he made the catch.
      </div>
      {video("https://www.youtube.com/embed/6exNu140P5I?start=727&end=739&autoplay=1")}
      <div>
        Michigan could be poised to take advantage of this defender, though. Sneaking out RB Donovan Edwards 
        from the backfield on a wheel route would likely induce one-on-one coverage against a linebacker, a 
        matchup Edwards could exploit with his speed and agility in space.
      </div>
      <br />
      <div>
        Overall, Washington’s pass defense is somewhat simple, but executed mechanically by its secondary. 
        Their ability to interchange their coverages from all pre-snap looks, along with their tendency to 
        leave defenders in the middle of the field, could pose some challenges for McCarthy, who will have 
        to stay disciplined in his pass selection to avoid mistakes that could result in interceptions.
      </div>
      <h4><b>Run Offensive</b></h4>
      <div>
        Michigan will be the first team Washington has played all season who will center their offensive 
        attack around the run. Just two of Washington’s opponents had more rushing attempts than passing 
        attempts against the Huskies (Oregon State called 53% rush plays
        {citation(26, "https://gameonpaper.com/cfb/game/401524060")}
        and Tulsa called 51%
        {citation(27, "https://gameonpaper.com/cfb/game/401524004")}
        ). Given the level of success opponents had running the ball, this is somewhat surprising. 
        Texas and USC are the two best examples, running the ball on just 37% and 38% of plays despite 
        posting EPA/rush numbers of .44 
        {citation(28, "https://gameonpaper.com/cfb/game/401551788")}
        and .67
        {citation(29, "https://gameonpaper.com/cfb/game/401524048")}
        , respectively (to put this in perspective, the best EPA/rush for a team over the entire season 
        was less than half what USC achieve against Washington: .32, by LSU). Jim Harbaugh is not one to 
        shy away from the run, however, and Washington defensive coordinator Chuck Morrell will surely 
        be prepared for a rush-heavy game.
      </div>
      <br />
      <div>
        While it is difficult to predict what adjustments Morell will make to account for the run, it is 
        easier to see what has given the Huskies’ defensive front difficulties so far this season. For one 
        thing, Washington linebackers are prone to get into the wrong gaps, even when they are ready for a 
        running play. 
      </div>
      <br />
      <div>
        Midway through the second quarter in their first matchup against Oregon, Washington stacked the box 
        with seven defenders on 1st & 10. As Nix handed the ball off to Irving, LBs Ulofoshio and Tuputala 
        flooded down to the line of scrimmage, but both hurried to the same gap on the backside of the play, 
        opening up a huge hole for Irving on the weak side A-gap, which he exploited by charging five yards 
        before being touched.
      </div>
      {video("https://www.youtube.com/embed/6exNu140P5I?start=3843&end=3854&autoplay=1")}
      <div>
        This clip also displays a second weakness of Washington’s rushing defense: after contact is made by 
        safety Dominique Hampton at the 30 yard line, Irving motors forward for an additional <i>four yards</i>, 
        requiring three Huskies defenders to take him down. The same story played out just a few minutes later in the same game:
      </div>
      {video("https://www.youtube.com/embed/6exNu140P5I?start=3996&end=4013&autoplay=1")}
      <div>
        Once he slips out of a tackle in the backfield, Irving is awaited by a massive hole, again gaining 
        five yards before making contact with a defender and again pushing forward for four more yards before 
        finally being taken down in a tackle that required the combined muster of four defenders.
      </div>
      <br />
      <div>
        This was not just Bucky Irving running wild. On their second play from scrimmage in the Sugar Bowl, 
        Texas converted on 2nd & 15 when freshman RB CJ Baxter darted through a wide open B gap and got 11 
        yards before meeting Tuputala, and then pushed upfield five more yards before succumbing to his tackle.
      </div>
      {video("https://www.youtube.com/embed/gS7WczOL5YQ?start=42&end=49&autoplay=1")}
      <div>
        Needless to say, Washington’s defensive linemen and linebackers are liable to get pushed around, getting 
        knocked clear out of gaps so badly that they end up with their backs turned to the play
        {citation(30, "https://youtu.be/6exNu140P5I?si=BPkGVpnH2Qu6ivRn&t=1645" , "#94 & #10 on this play, for example")}
        . Even when they get into the backfield unblocked, Washington defenders habitually delete themselves 
        from the play, as in this touchdown where Tupuola-Fetui is left completely free, but flies in at a bad 
        angle and is forced to dive at Irving from behind, whiffing on what could have been a tackle for a loss:
      </div>
      <br />
      <div>
        Big gaps, missed tackles, and susceptibility to cuts are all ingredients for a big game from Michigan 
        career rushing touchdown leader
        {citation(31, "https://mgoblue.com/documents/2023/9/7/fbl-record-book-2023.pdf")}
        Blake Corum; unless Washington tightens at the seams, expect a resounding showing from Corum in his 
        final game wearing the winged helmet.
      </div>
      <br />
      <div>
        Michigan can also use the run game to complement their passing attack, especially through the use 
        of play action. Washington’s linebackers and safeties are liable to bite hard on fake handoffs in 
        their attempt to contain the run, as they did on this play early in the game against Oregon:
      </div>
      {video("https://www.youtube.com/embed/6exNu140P5I?start=1329&end=1344&autoplay=1")}
      <div>
        Here, three Washington players (Muhammad, Ulofoshio, and Tuputala) all flood to the line of scrimmage 
        when Nix feigns the handoff, and both safeties (Hampton and Fabiculanan) start creeping down in 
        anticipation. Once Nix pulls the ball and throws to the flat, WR Troy Franklin is left in space with 
        just CB Elijah Jackson to beat. Jackson misses the tackle, and Fabiculanan, having started toward 
        the backfield because of the play fake, gets there too late to prevent him from picking up the first down.
      </div>
      <br />
      <div>
        In the Sugar Bowl, Texas also used play action deep in Washington territory to get to the one yard line:
      </div>
      {video("https://www.youtube.com/embed/gS7WczOL5YQ?start=288&end=297&autoplay=1")}
      <div>
        Again, all three linebackers stream down to take on blocks for the run game, vacating the middle of the 
        field and giving Ewers a clean throwing lane to find his receiver.
      </div>
      <h4><b>Outlook</b></h4>
      <div>
        After surviving the defensive gauntlet of Penn State, Ohio State, Iowa, and Alabama, Michigan’s offense 
        should have an easier time in their final test of the season. Given Washington’s season-long struggles 
        against the run and their lack of real weapons in the secondary, the Wolverines will be hoping to return 
        to the production they saw in the first half of the season against defenses like Minnesota, Rutgers, 
        and Michigan State. If the offensive line holds firm, Michigan should be able to establish a dominant 
        rushing attack early in the game, opening up opportunities to poke holes in Washington’s secondary by 
        leaning on play action, incorporating its running backs into the receiving game, and using motion to 
        create favorable one-on-one matchups. If they do so, the Wolverines could put up a score in the 35-40 range.
      </div>
      <br />
      <div>
        By all accounts, this is Michigan’s game to lose. On the doorstep of the first 15-0 season in Big Ten 
        history
        {citation(32, "https://www.ncaa.com/news/football/article/2023-01-09/college-football-champions-every-undefeated-team-win-national-championship-1936")}
        and the first outright national championship for the maize and blue since 1948
        {citation(33, "https://www.houstonchronicle.com/texas-sports-nation/college/article/michigan-washington-outright-national-titles-18593720.php")}
        , Team 144 is staring down a defense a step below what it has had to overcome to make it to this game. 
      </div>
      <br />
      <div>
        Still, one can’t help but fear the Huskies’ resilience and outright refusal to lose. Close game after 
        close game, through a red zone stand in the Sugar Bowl’s final seconds, a pick-six in the fourth quarter 
        to avoid an upset
        {citation(34, "https://www.youtube.com/watch?v=BaebcXBsgCE")}
        , and an Oregon field goal attempt sailing just inches right of the goalposts as the clock ticked to 
        zero, Washington has clawed away one victory after another. If there were ever a team “destined” to 
        win, Washington sure seems like the one.
      </div>
    </div>
  )
}