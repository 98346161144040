import React from "react";
import ProjectPage from "../components/projects/ProjectPage";
import withRouter from "../components/withRouter";
import { useParams } from "react-router-dom";
import Error from "./Error";

// Bootstrap imports
import Container from "react-bootstrap/Container";

import projects from "../constants/project-descriptions";

// Styling
import "../components/projects/projects.css";

function Project() {
  // The route of the project in the url
  const { projectId } = useParams();
  const project = projects.find((p) => p.route === projectId)
  if (!project) return (
    <Error />
  )

  return (
    <Container className="main">
      <ProjectPage title={project.title} authors={project.authors} text={project.text} images={project.images} />
    </Container>
  );
}

export default withRouter(Project);
