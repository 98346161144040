import React from "react";

// Bootstrap imports
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import secondary from "../images/icons/secondary.png";

export default function Navbar() {
  return (
    <header className="flex-center">
      <Container>
        <Desktop />
        <Mobile />
      </Container>
    </header>
  );
}

function Desktop() {
  return (
    <Row className="nav-desktop">
      <Col md={3}>
        <a href="/">
          <img src={secondary} alt="wsa-logo" height="100" />
        </a>
      </Col>
      <Col md={9} className="nav-buttons">
        <a href="/">
          <button>Home</button>
        </a>
        <a href="/projects">
          <button>Projects</button>
        </a>
        <a href="/featured">
          <button>Featured</button>
        </a>
        <a href="/blog">
          <button>Blog</button>
        </a>
      </Col>
    </Row>
  );
}

function Mobile() {
  return (
    <Row xs={2} className="nav-mobile">
      <Col>
        <a href="/">
          <img src={secondary} alt="wsa-logo" width="70%" />
        </a>
      </Col>
      <Col>
        <DropdownButton
          id="dropdown-basic-button"
          title="Menu "
          size="lg"
          align="end"
        >
          <Dropdown.Item href="/">Home</Dropdown.Item>
          <Dropdown.Item href="/projects">Projects</Dropdown.Item>
          <Dropdown.Item href="/featured">Featured</Dropdown.Item>
          <Dropdown.Item href="/blog">Blog</Dropdown.Item>
        </DropdownButton>
      </Col>
    </Row>
  );
}
