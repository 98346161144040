import React from "react";

// Bootstrap imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import eboard from "../../constants/eboard";

export default function Eboard() {
  return (
    <div>
      <h1 className="home-projects-container home-eboard-container">
        Executive Board
      </h1>
      <Row className="home-eboard-bio">
        {eboard.map((member) => {
          return (
            <Leader
              key={member.name}
              image={member.image}
              name={member.name}
              title={member.title}
              bio={member.bio}
            />
          );
        })}
      </Row>
    </div>
  );
}

// Component for each member bio - pass in picture, name, title, bio
function Leader({ image, name, title, bio }) {
  return (
    <Col xs={12} sm={6} md={3} style={{ float: "none", margin: "0 auto" }}>
      <div className="bio center">
        <img src={image} alt={name} loading="lazy" />
        <h4>{name}</h4>
        <h5>{title}</h5>
        <p>{bio}</p>
      </div>
    </Col>
  );
}
