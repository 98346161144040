import RoseBowl from "../images/football/rose-bowl.jpg";
import nationalChampionship from "../images/football/natty.png";
import MichiganPlays from "../images/football/roman-wilson.jpg";
import Kyler from "../images/football/kyler-murray.jpg";

const blog = [
  {
    title: "How Quarterback Size Distorts Recruiting Rankings",
    date: "6/10/2024",
    author: "David McDermott",
    image: Kyler,
    text: [`Perusing the profiles of high school quarterback hopefuls on the QB-focused recruiting 
      website QB Hit List, a quarterback evaluator can quickly find themself drowned in the quotes 
      players insert at the top of their...`],
    route: "qb-size",
  },
  {
    title: "Ranking Michigan’s Biggest Plays of the 2023 Football Season by EPA and WPA",
    author: "David McDermott",
    date: "1/18/2024",
    image: MichiganPlays,
    text: [`As the final flecks of maize and blue confetti fell on the field at NRG stadium in 
    Houston after Michigan’s first national championship victory since 1997, I found myself already 
    nostalgic for Team 144, no doubt the greatest Michigan...`],
    route: "michigan-plays",
  },
  {
    title: "2024 National Championship Pre-Game Analysis: Michigan Offense vs Washington Defense",
    author: "David McDermott",
    date: "1/7/2024",
    image: nationalChampionship,
    text: [`There is no question which team analytics favor in the final National Championship 
    game of the 4-team College Football Playoff era. FEI, an opponent-adjusted scoring-based 
    metric, expects Michigan to win by...`],
    route: "national-championship",
  },
  {
    title: "2024 Rose Bowl Pre-Game Analysis: Michigan Offense",
    date: "12/29/2023",
    author: "David McDermott",
    image: RoseBowl,
    text: [`Michigan and Alabama are set to meet in the Rose Bowl, with Michigan the slight 
    favorite in Vegas (two point favorites, at the time of writing) and the slight underdog 
    among the general public (52% of ESPN...`],
    route: "rose-bowl",
  }
]

export default blog;