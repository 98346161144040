const posts = [
  {
    title: "How Quarterback Size Distorts Recruiting Rankings",
    author: "David McDermott",
    date: "6/10/2024",
    route: "qb-size",
  },
  {
    title: "Ranking Michigan’s Biggest Plays of the 2023 Football Season by EPA and WPA",
    author: "David McDermott",
    date: "1/18/2024",
    route: "michigan-plays",
  },
  {
    title: "2024 National Championship Pre-Game Analysis: Michigan Offense vs Washington Defense",
    author: "David McDermott",
    date: "1/7/2024",
    route: "national-championship",
  },
  {
    title: "2024 Rose Bowl Pre-Game Analysis: Michigan Offense",
    author: "David McDermott",
    date: "12/29/2023",
    route: "rose-bowl", //use route to find post content
  }
]

export default posts;