// import jake from "../images/people/jake.jpg";
import zack from "../images/people/zack.jpg";
import maddie from "../images/people/maddie.jpg";
import connor from "../images/people/connor.JPG";
import lucas from "../images/people/lucas.jpg";
import ian from "../images/people/ian.jpg";
import padma from "../images/people/padma.jpg";
import evan from "../images/people/evan.jpg";
import yang from "../images/people/justin_yang.jpg";
// import cole from "../images/people/cole.png";

const eboard = [
  {
    image: zack,
    name: "Zack Eisman",
    title: "President",
    bio: `Zack is a senior from Northville, MI. He is majoring Information Analytics with a minor
          in business. He grew up playing soccer but loves to watch other sports as well. 
          Additionally, his favorite hobby is buying and selling sports cards and memorabilia.`,
  },
  {
    image: maddie,
    name: "Maddie Coe",
    title: "Vice President",
    bio: `Maddie is a junior from Northville, MI. She is majoring in Data Science with a minor
          in mathematics. She loves watching all sports, especially football, soccer and hockey.
          She was a project lead for the Michigan Hockey Player Cards project.`,
  },
  {
    image: connor,
    name: "Connor O'Malley",
    title: "Finance Officer",
    bio: `Connor is a senior from Midland, MI. He is majoring in Industrial 
          Operations Engineering. He enjoys snowboarding, playing Valorant, 
          competing with his IM volleyball team, and watching football and hockey.`,
  },
  {
    image: lucas,
    name: "Lucas Van Houten",
    title: "Projects Officer",
    bio: `Lucas is a senior from Troy, MI studying Computer Science. 
          He was the project lead for the Basketball March Madness Predictor project and 
          currently works on the UM Student Life Web Solutions team as a Web Designer.
          He enjoys running, playing IM soccer, and snowboarding.`,
  },
  {
    image: ian,
    name: "Ian Loree",
    title: "Education Officer",
    bio: `Ian is a senior from Okemos, MI studying Data Science through LSA.
          He is a trumpet player in the Michigan Marching Band and additionally enjoys
          running, reading, and watching hockey at Yost.`,
  },
  {
    image: padma,
    name: "Padma Danturty",
    title: "External Affairs Officer",
    bio: `Padma is a senior studying Data Science with a History minor in LSA. She is from 
          Illinois and enjoys watching cricket, playing card games, and writing for the 
          Michigan Gayly. This past summer, she interned at Ally Financial as a data analyst.`,
  },
  {
    image: evan,
    name: "Evan Chechak",
    title: "Social Officer",
    bio: `Evan is a junior from Northville, MI studying Computer Science through LSA.
          He has been a project lead on the NCAA Hockey Player Projections and NFL Draft Predictor
          projects. Additionally, he is also an amusement park enthusiast.`,
  },
  {
    image: yang,
    name: "Justin Yang",
    title: "Senior Advisor",
    bio: `Justin is a senior majoring in Computer Science with a minor in Applied Statistics. 
        He led the Michigan Hockey Project (2021-2022) and was club president until Winter 2024. 
        He enjoys snowboarding and playing tennis.`,
  }
];

export default eboard;