import {citation, video, link} from "../helper";

export default function MichiganPlays() {

  return (
    <div>
      <div>
        As the final flecks of maize and blue confetti fell on the field at NRG stadium in Houston 
        after Michigan’s first national championship victory since 1997, I found myself already nostalgic 
        for Team 144, no doubt the greatest Michigan team I had ever had the privilege of rooting for. 
        The feeling was bittersweet; the elation of completing a dominant, undefeated season with a 
        statement championship game victory was quickly nudged out of mind by the disappointment that 
        accompanied the end of the season, and, more importantly, the end of <i>this team</i>.
      </div>
      <br />
      <div>
        In the days that followed, I found myself replaying moments of the 2023 campaign in my mind, 
        constantly ranking and reranking each memory in my list of favorites. Was watching 
        {link("Roman Wilson leap miles", "https://www.youtube.com/watch?v=zS9LxhhdGXA")}
        into the Pasadena mountaintops to snatch J.J. McCarthy’s tipped pass out of the air before 
        turning upfield and darting to the five yard-line the best highlight of the year? Was it 
        {link("Blake Corum jabbing inside", "https://www.youtube.com/shorts/UwvywKlTNJ8")}
        before decisively cutting left and accelerating through a closing gap for a touchdown, 
        then slamming the 6-5 to the camera to honor Zak Zinter, injured on the previous play? 
      </div>
      <br />
      <div>
        While these plays were among the most iconic, I began wondering which plays were the most 
        <i>impactful</i>. Which plays were the most important to the championship run and caused the biggest 
        swings in Michigan’s favor? To quantify the impact and importance of individual plays throughout 
        Michigan’s amazing season, I turned to analytics.
      </div>
      <h4><b>EPA and WPA</b></h4>
      <div>
        There are two approaches one could take to classify impactful plays.
      </div>
      <br />
      <div>
        One approach would be to look at which plays mattered most in the context of the game. 
        This approach would try to identify the plays that caused the biggest in-game swings, shifting 
        momentum from one team to the other at key moments or slamming the door on potential comebacks. 
      </div>
      <br />
      <div>
        To quantify this impact, a natural approach is to look at how much a given play affected each 
        team’s chance of winning the game. This is the crux of 
        {link("Win Probability Added", "https://en.wikipedia.org/wiki/Win_probability_added")}
        (WPA), a metric that measures the change in projected win probability for a team before and 
        after the play of interest. The specifics of how win probability is calculated are often complex, 
        and vary from one model to another. For football, estimates usually include a pregame metric 
        of each team’s strength, the current score, which team has possession, the current down and 
        distance to a first down, and more. The calculation of WPA itself is straightforward: simply 
        subtract a team’s win probability after a given play from its win probability before the play.
      </div>
      <br />
      <div>
        By nature of its calculation, using absolute WPA to quantify importance favors plays from close 
        games. For example, a 99-yard pick-six would seem like a “big play” in any game, causing a 
        14-point swing to the defense (taking a likely seven points away from the team that threw the 
        interception and adding seven points to the team that intercepted it). If the pick happened 
        late in the second half of a 35-0 game, though, it would have a low WPA. Even if the score 
        becomes 35-7, the win probability of the trailing team would remain low, given a large deficit 
        to overcome and little time to do it.
      </div>
      <br />
      <div>
        This can be seen both as an advantage and as a disadvantage. On the one hand, it makes sense 
        that the “biggest plays” of a season would be the ones that made the difference in tight games. 
        Even if the plays themselves may be less explosive, the feeling in the moment is certainly more 
        exciting when it feels like everything is on the line. On the other hand, this may discount 
        plays that are explosive and significant just because they happened in games that one team led 
        by a wide margin, plays that are no doubt still significant to the players that make them.
      </div>
      <br />
      <div>
        An alternative approach would be to look only at the point swing plays cause, not at the context 
        of the game around them. This approach would see the 99-yard pick-six from above as impactful 
        no matter when it happens; a 14-point swing is still a 14-point swing, even if it happens in a 
        game that is all but over.
      </div>
      <br />
      <div>
        To quantify this swing, we can use 
        {link("Expected Points Added", "https://bestballstats.com/expected-points-added-a-full-explanation/")}
        (EPA). EPA is similar to WPA; instead of looking at each team’s win probability, though, it looks 
        at the number of points one would expect the offense to score on the ongoing drive. To estimate 
        how many points a team will score, EPA models typically use a team’s position on the field, current 
        down, and distance to a first down. For example, the expected points of a team with 1st & goal at 
        the 1 yard line will be near seven, while a team facing 3rd & 13 at their opponents 35 yard line 
        would likely be closer to three, as a team in that situation would be expected to settle for a field 
        goal the majority of the time.
      </div>
      <br />
      <div>
        To calculate Expected Points Added from Expected Points is as easy as it is to calculate Win 
        Probability Added from Win Probability: simply subtract a team’s Expected Points after a given 
        play from their Expected Points before.
      </div>
      <br />
      <div>
        Using EPA over WPA favors explosiveness over importance; plays that kill a threatening opponent’s 
        drive or save a drive from ruin will have high EPA even in the least close of games. The difference 
        is demonstrated in the number of games with high-EPA and high-WPA plays during the Michigan season. 
        The ten plays with the highest EPA all came from different games. The ten plays with the highest WPA, 
        however, all came from the same three games: the games against Ohio State, Alabama, and Washington – 
        the three closest games of Michigan’s season.
      </div>
      <br />
      <div>
        Which metric to use is a matter of preference. If you care more about game impact, use WPA. If you 
        prefer highlight reel plays, use EPA. Below is a compilation of the top ten plays of the Michigan 
        season, first by WPA, then by EPA. Both the WPA and EPA metrics used are from 
        {link("Game On Paper", "https://gameonpaper.com/cfb/year/2023/type/3/week/1?group=80")}
        , a scorecard site that uses data from ESPN. For EPA, the top plays include plays with negative EPA 
        for Michigan’s opponent. Both lists only include plays in Michigan’s favor, not high-EPA or high-WPA 
        plays for their opponents.
      </div>
      <br />
      <div>
        Two trends stand out between the lists. First, while EPA and WPA certainly correlate, that correlation 
        disintegrates in a schedule like Michigan’s, where the vast majority of the games were crushes, not 
        close contests. Indeed, only one play was among the top ten in both EPA and WPA. Second, EPA tends 
        to favor defense, while WPA tends to favor offense. All of the top ten plays by EPA are defensive, 
        largely because of the high impact of turnovers in EPA. By contrast, just three of the top ten by WPA 
        are defensive.
      </div>
      <br />
      <div>
        Both metrics, however, evoke fond memories of the 2023 Michigan football season – memories almost 
        exciting enough to satiate my appetite for Team 144 content. Enjoy.
      </div>
      <h4><b>Top 10 Plays by EPA</b></h4>
      <div>
        <b>10. </b>
        {link("Braiden McGregor strip sacks Deacon Hill in the B1G Championship Game. EPA: -5.28 for Iowa", "https://youtube.com/clip/UgkxVho81bYnMdPC8UBdyv46F1Da7U__MxVl?si=4ODDdnRwpa2KUCd0")}
      </div>
      <br />
      <div>
        <b>9. </b>
        {link("Quinten Johnson forces a Jalen Milroe fumble in the Rose Bowl. EPA: -5.33 for Alabama", "https://youtube.com/clip/UgkxVho81bYnMdPC8UBdyv46F1Da7U__MxVl?si=4ODDdnRwpa2KUCd0")}
      </div>
      <br />
      <div>
        <b>8. </b>
        {link("Rod Moore intercepts Tayven Jackson in the red zone. EPA: -5.52 for Indiana", "https://youtube.com/clip/UgkxFipaVr2GpEf-XeNWIos0Mtap2hSCtdNG?si=Ov9s8PKw0Hmuh55K")}
      </div>
      <br />
      <div>
        <b>7. </b>
        {link("Kris Jenkins intercepts Hayden Timosciek, setting Michigan up at the 1 yard line. EPA: -5.53 for Bowling Green", "https://youtube.com/clip/UgkxbYenb1xqlNck6YSyi3uVaJ9fTGWWs6qA?si=jC6xBi85VG2welBJ")}
      </div>
      <br />
      <div>
        <b>6. </b>
        {link("Michigan stops Heinrich Haarberg on 4th & 1 at Michigan’s 12 yard line. EPA: -5.59 for Nebraska", "https://youtube.com/clip/UgkxU_rFyFm9xMTd57xfmH5LDRaaWIg-ZYX-?si=KebU6yxcDV6RpuGO")}
      </div>
      <br />
      <div>
        <b>5. </b>
        {link("Mike Sainristil intercepts Michael Penix Jr. and returns it to the Washington 8 yard line in the CFP National Championship Game. EPA: -5.99 for Washington", "https://youtube.com/clip/UgkxCVYApf4Z_ExEdgp012ql0Wfm_ExsOOaV?si=75tVVHWzdXQcNWQO")}
      </div>
      <br />
      <div>
        <b>4. </b>
        {link("Will Johnson intercepts Kyle McCord and returns it to the Ohio State 7 yard line in The Game. EPA: -6.08 for Ohio State", "https://youtube.com/clip/UgkxYHUFudgPPkQgS3WhEEi1S8FdthmnQ-CM?si=wu-BUj5q-O7sGjao")}
      </div>
      <br />
      <div>
        <b>3. </b>
        {link("Derrick Moore returns Michael Barrett’s strip sack of Taulia Tagovailoa for a touchdown. EPA: -6.19 for Maryland", "https://youtube.com/clip/UgkxFh_JUoR8c95QCbZe9eb0sFqYq1ZI2Oo6?si=usm6JVqEodwwel61")}
      </div>
      <br />
      <div>
        <b>2. </b>
        {link("Will Johnson picks off Athan Kaliakmanis for a touchdown. EPA: -7.41 for Minnesota", "https://youtube.com/clip/UgkxEK_ZdFi5shlEFRBFyaxdYT_PCV5RER9g?si=0SiFYJ-Lj8wNINfo")}
      </div>
      <br />
      <div>
        <b>1. </b>
        {link("Mike Sainristil returns an interception off Gavin Wimsatt 71 yards for a touchdown. EPA: -8.82 for Rutgers", "https://youtube.com/clip/UgkxWsF0uw5p3dezHmjihop_4qcFwoX7mM7x?si=A9eyfaswuDTK32qc")}
      </div>
      <h4><b>Top 10 Plays by WPA</b></h4>
      <div>
        <b>10. </b>
        {link("Blake Corum scores a touchdown on 4th & goal to open scoring in The Game. WPA: 13.3 (58.2% → 71.6%)", "https://youtube.com/clip/UgkxBoATCJVDFsoH6Sv08U_CatjYomz5Whho?si=FjfTbsYUTgbKbAYS")}
      </div>
      <br />
      <div>
        <b>9. </b>
        {link("Donovan Edwards breaks off a 46 yard run to score his second touchdown of the CFP National Championship Game. WPA: 13.3 (73.1% → 86.4%)", "https://youtube.com/clip/Ugkx1o7MPRddawsO1OrizJq5dlEndftCn5kK?si=Fr7zDOQUaKmlsBaw")}
      </div>
      <br />
      <div>
        <b>8. </b>
        {link("Blake Corum scores the iconic (post-Zinter injury) touchdown to break the tie late in the 3rd quarter of The Game. WPA: 13.8 (67.4% → 81.1%)", "https://youtube.com/clip/UgkxMHhe1exl65uCx0MyujBip6ix9NllYMe_?si=Ldxq9IVW-trPlnFq")}
      </div>
      <br />
      <div>
        <b>7. </b>
        {link("J.J. McCarthy threads the needle to Roman Wilson for a touchdown, putting Michigan up two scores in The Game. WPA: 14.1 (70.5% → 84.6%)", "https://youtube.com/clip/Ugkx9WdtA-1aZMRdb3Gq92_0ETtrQRVCh96p?si=Exg0I46vKzgknMZJ")}
      </div>
      <br />
      <div>
        <b>6. </b>
        {link("Donovan Edwards opens the CFP National Championship Game scoring with a 41 yard touchdown run. WPA: 14.9 (63.0% → 77.9%)", "https://youtube.com/clip/Ugkx7hdU7OTmGS9SJemKkY876Cc2X5sKKe4s?si=tcsjKu2G495F6DEZ")}
      </div>
      <br />
      <div>
        <b>5. </b>
        {link("Rod Moore calls game. WPA: 15.4 (80.7% → 96.1%)", "https://youtube.com/clip/UgkxuuEPYUnu9ZzSjAua-yqYhoOp0ifGCLMi?si=-EiX6xR8Otpr0qCm")}
      </div>
      <br />
      <div>
        <b>4. </b>
        {link("Quinten Johnson forces a Jalen Milroe fumble in the 4th quarter of the Rose Bowl. WPA: 15.4 (27.5% → 42.9%)", "https://youtube.com/clip/Ugkx8wF8nz6IXrnLXtY8tdYPiWcZce2yLb_h?si=YbGJaPvj5zttQrkZ")}
      </div>
      <br />
      <div>
        <b>3. </b>
        {link("Tyler Morris scores on 3rd & 10 from the 39 yard line to give Michigan the lead in the Rose Bowl. WPA: 16.6 (57.4% → 74.0%)", "https://youtube.com/clip/UgkxkxT9RhpiLzY0tZO5QQGwBreUXEhmdAgC?si=x3nF57DIoA4s03Cg")}
      </div>
      <br />
      <div>
        <b>2. </b>
        {link("Roman Wilson hops into the end zone to tie the Rose Bowl with 1:38 to go in the 4th quarter. WPA: 16.9 (29.3% → 46.3%)", "https://youtube.com/clip/UgkxSxbU-PCRdynFdh7HULR05wBUo-grNw-6?si=dKiYCRrynG-a3T7O")}
      </div>
      <br />
      <div>
        <b>1. </b>
        {link("Michigan’s defense stuffs Jalen Milroe on 4th & goal in OT to clinch their first-ever playoff victory. WPA: 19.9 (80.1% → 100%)", "https://youtube.com/clip/UgkxNX-24ca4-632wlMD7p8VaQs36OY5PpUo?si=od9oNCQZlxHslWZu")}
      </div>
    </div>
  )
}