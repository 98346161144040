import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

// Bootstrap imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Logo import
import secondary from "../../images/icons/secondary.png";

export default function Contact() {
  return (
    <div className="home-contact" id="contact">
      <Row>
        <Col xs={12} md={6} lg={6}>
          <Form />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <img src={secondary} alt="wsa-logo" loading="lazy" height="300" />
        </Col>
      </Row>
    </div>
  );
}

function validEmail(email) {
  return String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

function validateForm(name, email, message, spamCheck) {
  if (spamCheck !== "1") {
    return false;
  }
  if (!name) {
    alert('Please fill in the name field.');
    return false;
  }
  if (!email || !validEmail(email)) {
    alert('Please fill in a valid email.');
    return false;
  }
  if (!message) {
    alert('Please fill in the message field.');
    return false;
  }
  return true;
}

function Form() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm(form.current['from_name'].value, 
        form.current['from_email'].value, form.current['message'].value, 
        form.current['contact_me_by_fax_only'].value)) {
      return;
    }

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, 
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
    .then((result) => {
      alert("Message has been successfully sent to WSA.");
    }, (error) => {
      alert("There is an error sending your message.");
    });
  }

  return (
    <div>
      <h1>Contact WSA</h1>
      <form ref={form}>
        <label>Name</label>
        <br />
        <input type="text" name="from_name" placeholder="Your full name (required)"/>
        <br />
        <label>Email</label>
        <br />
        <input type="email" name="from_email" placeholder="Your email address (required)"/>
        <br />
        <label>Subject</label>
        <br />
        <input type="text" name="subject" placeholder="Subject (optional)"/>
        <br />
        <label>Message</label>
        <br />
        <textarea name="message" placeholder="Your message (required)"/>
        <br />
        {/*This bottom input is invisible to users but removes bot spam*/}
        <input type="checkbox" name="contact_me_by_fax_only" value="1" style={{display:"none"}} tabindex="-1" autocomplete="off" />
      </form>
      <button className="center" onClick={sendEmail}>
        Send Message
      </button>
    </div>
  );
}
