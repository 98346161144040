import React, { useEffect } from 'react';
import Header from '../components/projects/Header';
import Cards from '../components/projects/ProjectCards';

// Bootstrap imports
import Container from 'react-bootstrap/Container';

// Styling
import '../components/projects/projects.css';

export default function Projects() {
  useEffect(() => {
    document.title = 'WSA Projects';
  }, []);
  return (
    <Container className="main">
      <Header />
      <Cards />
    </Container>
  );
}
