import React from "react";
import banner from "../../images/banner.png";

// Bootstrap imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Banner() {
  return (
    <Row className="animated animatedFadeInUp fadeInUp">
      <Col xs={12} md={6}>
        <Text />
      </Col>
      <Col xs={12} md={6}>
        <Image />
      </Col>
    </Row>
  );
}

// Freepik image
function Image() {
  return (
    <img src={banner} alt="freepik" height="420" width="500" />
  );
}

function Text() {
  return (
    <div className="center home-banner-text">
      <h1>Wolverine Sports Analytics</h1>
      <h3>where data meet sports</h3>
      <p>
        A University of Michigan student-led club focused on sports analytics.
        While we are computer science, data science, and engineering nerds, we
        love sports and have a vision to utilize our skillset to analyze sports.
        Our club is open to any interested Michigan students. We are also always
        looking to take on new projects. Interested in joining WSA? 
        Click <a href='https://forms.gle/oJtAyBRDyURojDth8' target="_blank" rel="noreferrer">here</a> to sign up
        on our interest form. <a href="#contact">Contact us</a> for any inquiries.
      </p>
    </div>
  );
}
