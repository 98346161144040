import React from "react";

// Bootstrap imports
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import primary from "../images/icons/primary.png";

export default function Footer() {
  return (
    <footer>
      <Container>
        <Desktop />
        <Mobile />
      </Container>
    </footer>
  );
}

function Desktop() {
  return (
    <Row className="footer-desktop">
      <Col md={3}>
        <h5>Sitemap</h5>
        <ul>
          <a href="/">
            <li>Home</li>
          </a>
          <a href="/projects">
            <li>Projects</li>
          </a>
          <a href="/featured">
            <li>Featured</li>
          </a>
          <a href="/blog">
            <li>Blog</li>
          </a>
          <a href="/#contact">
            <li>Contact Us</li>
          </a>
        </ul>
      </Col>
      <Col md={6}>
        <h5>Disclaimer</h5>
        <h6>We do not endorse gambling or the use of our club projects for profit purposes.</h6>
        <p style={{ fontSize: '11px' }}>
          Any projects, events, or initiatives organized or sponsored by Wolverine Sports Analytics should not be used 
          for gambling purposes or any activities aimed at generating personal profit. We strictly prohibit the use of 
          our club's resources, reputation, or projects for such purposes. Our club is dedicated to educational and community-oriented endeavors.
        </p>
      </Col>
      <Col md={3}>
        <a href="/">
          <img src={primary} alt="wsa-logo" height="140" loading="lazy" />
        </a>
      </Col>
    </Row>
  );
}

function Mobile() {
  return (
    <Row className="footer-mobile">
      <Col xs={3}>
        <h5>Sitemap</h5>
        <ul>
          <a href="/">
            <li>Home</li>
          </a>
          <a href="/projects">
            <li>Projects</li>
          </a>
          <a href="/featured">
            <li>Featured</li>
          </a>
          <a href="/blog">
            <li>Blog</li>
          </a>
          <a href="#contact">
            <li>Contact Us</li>
          </a>
        </ul>
      </Col>
      <Col xs={8}>
        <h5>Disclaimer</h5>
        <p style={{ fontSize: '10px' }}>
          Any projects, events, or initiatives organized or sponsored by Wolverine Sports Analytics should not be used 
          for gambling purposes or any activities aimed at generating personal profit. We strictly prohibit the use of 
          our club's resources, reputation, or projects for such purposes. Our club is dedicated to educational and community-oriented endeavors.
        </p>
      </Col>
      <Col xs={1}></Col>
    </Row>
  );
}
