import React, { /*useState,*/ useEffect } from "react";

// Bootstrap imports
import Container from "react-bootstrap/Container";

// Component imports
import Banner from "../components/home/Banner";
import Projects from "../components/home/Projects";
import Eboard from "../components/home/Eboard";
// import Leads from "../components/home/Leads";
import Contact from "../components/home/Contact";

// Styling
import "../components/home/home.css";

export default function Home() {
  useEffect(() => {
    document.title = "WSA";
  }, []);

  return (
    <div className="home-page">
      <div className="home-banner">
        <Container className="main">
          <Banner />
        </Container>
      </div>
      <div>
        <Container className="main">
          <Projects />
        </Container>
      </div>
      <div className="home-eboard">
        <Container className="main">
          <Eboard />
        </Container>
      </div>
      <div>
        <Container className="main">
          <Contact />
        </Container>
      </div>
    </div>
  );
}
