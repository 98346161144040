import React,{useState} from "react";

// Bootstrap imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import projects from "../../constants/project-summaries";
const groupBySeason = (projects) => {
    const seasonsOrder = [];
    const groupedProjects = {};
    projects.forEach((project) => {
        const { season } = project;
        if (!groupedProjects[season]) {
            groupedProjects[season] = [];
            seasonsOrder.push(season);
        }
        groupedProjects[season].push(project);
    });
    return { seasonsOrder, groupedProjects };
};

export default function ProjectCards() {
  const {seasonsOrder, groupedProjects} = groupBySeason(projects);
  const [activeKey, setActiveKey] = useState(seasonsOrder[0]);

  const handleToggle = (season) => {
      setActiveKey(activeKey === season ? null : season);
  };

  return (
    <Accordion defaultActiveKey={seasonsOrder[0]} className="projects-accordion">
        {seasonsOrder.map((season) => {
            return (
                <Card className="projects-accordion-card">
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey={season}
                        onClick={() => handleToggle(season)}
                        className="projects-accordion-toggle"
                    >
                        {season}
                        <span style={{
                                transform: activeKey === season ? 'rotate(270deg)' : 'rotate(90deg)',
                                transition: 'transform 0.2s',
                            }} >
                            ▶
                        </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={season}>
                    <Card.Body>
                        <Row className="projects-header animated animatedFadeInUp fadeInUp">
                            {groupedProjects[season].map((project) => {
                                return (
                                    <ProjectCard
                                        title={project.title}
                                        route={project.route}
                                        image={project.image}
                                    />
                                );
                            })}
                        </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )
        })}
    </Accordion>
  );
}

function ProjectCard({ title, route, image }) {
  function openRoute() {
    window.open(`/projects/${route}`, "_self");
  }

  return (
    <Col xs={12} md={6}>
      <div
        onClick={openRoute}
        className="projects-card flex-center"
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(20, 20, 20, 0.5)), url(${image})`,
        }}
      >
        <h2>{title}</h2>
      </div>
    </Col>
  );
}
