import React from "react";
import { Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
// Views
import Home from "./views/Home";
import Projects from "./views/Projects";
import Project from "./views/Project";
import Featured from "./views/Featured";
import Blog from "./views/Blog";
import Post from "./views/Post";
import Error from "./views/Error";

function App() {

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="projects">
          <Route index={true} element={<Projects />} />
          <Route index={false} path=':projectId' element={<Project />} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="featured" element={<Featured />} />
        <Route path="blog">
          <Route index={true} element={<Blog />} />
          <Route index={false} path=':postId' element={<Post />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
