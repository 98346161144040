import React, { /*useState,*/ useEffect } from "react";

// Bootstrap imports
import Container from "react-bootstrap/Container";

// Component imports
import Blog from "../components/blog/Blog";

// Styling
import "../components/blog/blog.css";

export default function Home() {
  useEffect(() => {
    document.title = "WSA Blog";
  }, []);

  return (
    <div className="home-page">
      <Container className="main">
        <Blog />
      </Container>
    </div>
  );
}
