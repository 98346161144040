import {citation, link} from "../helper";
import qbsize_fig1 from "../../../images/football/qbsize-size-vs-overall.png";
import qbsize_fig2 from "../../../images/football/qbsize-size-vs-skill.png";
import qbsize_fig3 from "../../../images/football/qbsize-stat-vs-ratings.png";
import qbsize_fig4 from "../../../images/football/qbsize-stats-by-size.png";

export default function MichiganPlays() {

  return (
    <div>
      <div>
      Perusing the profiles of high school quarterback hopefuls on the QB-focused recruiting website 
      QB Hit List, a quarterback evaluator can quickly find themself drowned in the quotes players 
      insert at the top of their page. While some players use this space to list their weightlifting 
      records or express their devotion to family, the most common theme found in these autobiographical 
      paragraphs is one of the importance of hard work. Quotes include: “I learned the value of hard 
      work by 
      {link("working hard", "https://qbhitlist.com/profile/jerry-bottleyiv-la/")}
      ,” “I'm willing to 
      {link("put in the work", "https://qbhitlist.com/profile/jett-niu-ut-1/")}
       and do whatever it takes to get to the next level,” “Highly motivated,  
      {link("extremely hard working", "https://qbhitlist.com/profile/tagg-harrison-ca/")}
       Quarterback with specific goals and personal expectations,” and “I love 
      {link("the grind!", "https://qbhitlist.com/profile/gabe-dombek-va/")}
       ” Underlying these lines is some version of the American Dream: a steadfast belief that, if an 
      aspiring athlete puts in the work, they will be rewarded with recognition and success.
      </div>
      <br />
      <div>
      Unfortunately for many of these athletes, the scouts whose evaluations define their future 
      opportunities may be more perceptive of traits that are not affected by prospects’ commitment 
      to the grind. Just under athletes’ quotes, QB Hit List displays a recruiting rating and 
      star-count. Below that (for quarterbacks that QBHL has been able to scout in person) is a 
      detailed scouting report, with written descriptions and numeric evaluations of specific 
      attributes, including “Accuracy,” “Poise,” and “Touch.” While the majority of these skills 
      may benefit from a quarterback’s hard work, the attribute that is listed first, above all 
      others, is one that the athlete has a little less control over: size. 
      {citation(1, "A quarterback has some control over their weight, of course, via dieting and workout regimen. Height, however, is up to fate, and even weight is largely influenced by physical properties like height and bone size, which high school QBs cannot change.")}
       Without exception, the opening line of QBHL scouting reports follows the same template: 
      “[quarterback name] has [adjective] size for a [graduation class] dual-threat quarterback 
      at [height and weight].” QBHL has chosen to forefront this trait, signaling its belief in 
      the importance of size to a quarterback’s future performance.  
      </div>
      <br />
      <div>
      Still, size is just one of ten categories that QBHL provides ratings on. The fact that it is 
      listed first alone does not produce a very strong argument that size is such an important 
      factor in quarterback scouting that it outshines the skill a quarterback can refine through 
      dedicated practice. 
      </div>
      <br />
      <div>
      A simple test of size’s importance in recruitment is to investigate the mathematical relationship 
      between a quarterback’s height and weight and the overall recruiting ranking they receive from 
      QBHL. Creating a scatter plot between recruits’ height/weight and rating and performing a linear 
      regression shows that size is, in fact, quite important to overall rating. 
      </div>
      <img src={qbsize_fig1} alt="Scatterplots of QB Size and Overall Ratings" loading="lazy" className="post-image" />
      <br />
      <div>
      As both height and weight increase, quarterbacks’ overall ratings tend to increase as well. 
      The relationship between weight and rating has an r-squared value 
      {citation(2, "Also referred to as a “coefficient of determination”")}
       of .45, indicating that about 45% 
      of a quarterback’s rating can be estimated by knowing only their weight. In other words, while size 
      is just one of ten player attributes that QBHL scouts rate, it tends to determine almost  
      <i>half</i> of their overall evaluation. 
      </div>
      <br />
      <div>
      Things get more interesting when looking at the other categories that scouts evaluate. Performing 
      linear regressions between height/weight and four particular attributes (Accuracy, Arm Strength, 
      Release, and Touch) reveals that the coefficient of determination between size and rating is over 
      .25 for <i>all</i> of these metrics.
      </div>
      <img src={qbsize_fig2} alt="Scatterplots of QB Size and Skill Ratings" loading="lazy" className="post-image" />
      <br />
      <div>
      While it’s logical that a quarterback’s weight would influence how strong their arm is, explaining 
      a relationship between a quarterback’s weight and accuracy is much more difficult. Still,  
      <i>one-fourth</i> of a quarterback’s accuracy rating can be predicted knowing solely the reading on 
      their scale, with no information about their actual throwing ability. It seems that scouts tend 
      to subconsciously overvalue size when rating quarterbacks, with their initial perceptions about 
      a quarterback’s look and build influencing their ratings of various other abilities.
      </div>
      <br />
      <div>
      One might object to focusing on those specific attribute ratings. The reason for doing so is that 
      data exists on many high school quarterbacks’ throwing ability that can be analyzed with those 
      throw-centric ratings in the same manner as height and weight. Since 2022, the high school 
      recruiting service Rivals has hosted day-long training camp for quarterbacks that use a ball 
      with integrated sensors that capture exit velocity, spin rate, and spiral efficiency 
      {citation(3, "Spiral efficiency refers to the percentage of spiral that is about the vertical axis of a ball, and is a measure of how tight a spiral is.")}
       every time they’re thrown. High schoolers who participate in these camps (called Wilson 
      QBX camps) have the average release time,  
      {citation(4, "Release time refers to the time between the start of a quarterback’s throwing motion and the ball leaving their hand. A lower release time is better.")}
       average throw velocity, average spiral efficiency, and maximum spin rate of every throw they make 
      during the camp published on a 
      {link("public leaderboard", "https://www.rivalscampseries.com/results/wilson-qbx-camp-sat/")}
      . Scraping both the throw statistics and the QBHL ratings for every quarterback who has participated 
       in one of these camps allows comparing objective throw data to subjective scout ratings of 
       arm strength, accuracy, and more.
      </div>
      <br />
      <div>
      Six specific pairings of Wilson QBX metrics and QBHL attribute ratings spring out as intuitive: 
      QBX’s Average Release Time and QBHL’s Release rating, QBX’s Average Velocity and QBHL’s Arm Strength 
      rating, QBX’s Maximum Spin Rate and QBHL’s Accuracy rating, QBX’s Maximum Spin Rate and QBHL’s Touch 
      rating, QBX’s Average Spiral Efficiency and QBHL’s Accuracy rating, and QBX’s Average Spiral 
      Efficiency and QBHL’s Touch rating. For each of these pairs, it makes sense to expect a better 
      QBX average to predict a higher QBHL rating, as a quarterback who tends to throw with higher and 
      tighter spiral likely has more control over their throws and more ability to hit targets with precision.
      </div>
      <img src={qbsize_fig3} alt="Scatterplots of QB Stats and Related Ratings" loading="lazy" className="post-image" />
      <br />
      <div>
      Plotting these pairs, however, reveals that a relationship between the objective metrics and scout ratings 
      is nonexistent. 
      </div>
      <br />
      <div>
      Only one of the pairs (QBX Average Velocity and QBHL Arm Strength rating) has an r-squared value over 0.03, 
      and even that pair is just 0.1. In other words,<i> the average velocity at which a quarterback throws </i>
      contributes just <i>10 percent</i> to the rating that a scout gives the strength of their arm. 
      A quarterback’s height, on the other hand, contributes <i>30</i> percent -- <i>three times</i> as much. 
      For a scout watching from the stands, picking up the speed at which a quarterback is gunning passes to his 
      receivers is difficult. Seeing how tall they look compared to the players around them, though, is not so hard.
      </div>
      <img src={qbsize_fig4} alt="Scatterplots College QB Stats by Size" loading="lazy" className="post-image" />
      <br />
      <div>
      Before castigating scouts' ratings as divorced from reality, it’s worth investigating whether quarterback 
      size is especially predictive of performance. Even if scouts appear to give outsized weight to a player’s 
      build, it may be the case that a quarterback’s size is of outsized importance.
      </div>
      <br />
      <div>
      This can be investigated in the same way, plotting college quarterbacks’ size against a few simple metrics 
      of performance and looking for the direction and significance of their linear relationship. Doing so 
      provides no saving grace for scouts: the relationship between a college quarterback’s size and any of 
      their yards per attempt, completion percentage, or quarterback rating, is insignificant.
      </div>
      <br />
      <div>
      At a glance, therefore, QBHL’s ratings seem to have a notable flaw. They’re systematically biased toward 
      larger quarterbacks both overall and in seemingly unrelated categories like throw accuracy, despite 
      larger quarterbacks performing no better at the collegiate level in a few basic metrics of quarterback success. 
      </div>
      <br />
      <div>
      To be clear, this is not an argument that high school scouting is hopelessly broken or useless; these ratings 
      come from a single website, looking at a single aspect of play (throwing ability) of a small sample of 
      quarterbacks. Still, they demonstrate that college scouting is in need of a reorientation. In a world of 
      omnipresent data, the team or scouting service that is best able to incorporate arising, objective, 
      data-based metrics will have a marked advantage in finding the best available players, including many 
      that would traditionally go overlooked. 
      </div>
    </div>
  )
}