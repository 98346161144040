import React from "react";
import PostPage from "../components/blog/PostPage";
import withRouter from "../components/withRouter";
import { useParams } from "react-router-dom";
import Error from "./Error";

// Bootstrap imports
import Container from "react-bootstrap/Container";

import posts from "../constants/blog-content";

// Styling
import "../components/blog/blog.css";

function Post() {
  // The route of the project in the url
  const { postId } = useParams();
  const post = posts.find((p) => p.route === postId)
  if (!post) {
    return (
      <Error />
    )
  }

  return (
    <Container className="main">
      <div className="post-page">
        <h1>{post.title}</h1>
        <p className="meta-data">by {post.author} | {post.date}</p>
        <PostPage title={post.title} content={post.route}/>
      </div>
    </Container>
  );
}

export default withRouter(Post);
