import React from "react";

// Bootstrap imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import blog from "../../constants/blog-summaries";

export default function Blog() {
  return (
    <div>
      <h1 className="blog-title">Blog</h1>
      <h5 className="blog-page-desc">A curated list of articles from our club members</h5>
      {blog.map((post) => {
        return (
          <Post
            key={post.title}
            title={post.title}
            date={post.date}
            author={post.author || "WSA Member"}
            text={post.text}
            image={post.image}
            route={post.route}
          />
        );
      })}
    </div>
  );
}

// Component for each project - pass in title, summary text and image
function Post({ title, date, author, text, image, route }) {

  return (
    <div className="blog" id={route}>
      <Row>
        <Col xs={12} md={7}>
          <a href={`/blog/${route}`}>
            <h3>{title}</h3>
          </a>
          <p>{author} | {date}</p>
          <p>{text}</p>
        </Col>
        <Col xs={12} md={5}>
          <img src={image} alt={title} loading="lazy" className="blog-image" />
        </Col>
      </Row>
      <hr />
    </div>
  );
}
