export const citation = (number, link, content) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" data-toggle="tooltip" title={content || link}><sup>[{number}]</sup></a>
  )
}

export const video = (link) => {
  return (
    <div className="iframe-container">
      <iframe src={link} title={link} allowFullScreen></iframe>
    </div>
  )
}

export const link = (text, url) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>
  )
}