import React, { useEffect } from "react";
import Error from "../../views/Error";
import RoseBowl from "./content/roseBowl";
import NationalChampionship from "./content/nationalChampionship";
import MichiganPlays from "./content/michiganPlays";
import QBSize from "./content/qbsize";

export default function PostPage({ title, content }) {
  useEffect(() => {
    document.title = title;
  }, [title]);

  function renderPost() {
    switch (content) {
      case 'rose-bowl': return <RoseBowl />;
      case 'national-championship': return <NationalChampionship />;
      case 'michigan-plays': return <MichiganPlays />;
      case 'qb-size' : return <QBSize />;
      default: return <Error />
    }
  }

  return (
    renderPost()
  );
}
