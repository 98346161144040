import image from "../../../images/football/rose-bowl-win-projections.png"
import {citation, video} from "../helper";

export default function RoseBowl() {

  return (
    <div>
      <h4><b>Overview</b></h4>
      <img src={image} alt="Rose Bowl Win Projections" loading="lazy" className="post-image" />
      <div>
        Michigan and Alabama are set to meet in the Rose Bowl, with Michigan the slight 
        favorite in Vegas (two point favorites, at the time of writing) and the slight underdog 
        among the general public (52% of ESPN Bowl Mania participants have selected the Crimson 
        Tide to win straight up, at the time of writing) despite being a fairly sizeable favorite 
        among advanced models.
      </div>
      <br />
      <div>
        SP+, the favorite predictive metric of analytic-oriented college football fans, likes 
        the Wolverines as nine point favorites with a 69% chance of winning
        {citation(1, "https://docs.google.com/spreadsheets/d/e/2PACX-1vRh9Slymcisd5-uEIvAD4zjGkJ7aeARseChhne-HdpyQeQiTSJeZD0WfyuG40O5S7Z20wz1XLYSUDUj/pubhtml#")}
        . FEI, an efficiency-based metric, gives Michigan a 76% chance of winning (ten point favorites)
        {citation(2, "https://www.bcftoys.com/2023-gp/")}
        . ESPN’s FPI is more measured, but gives the Maize and Blue a 56% chance of victory.
        {citation(3, "https://www.espn.com/college-football/game/_/gameId/401551786")}
      </div>
      <br />
      <div>
        Fulfilling these projections will require execution from a Michigan offense that looks to 
        continue the consistent, if not always flashy, production it has achieved throughout the season. 
        Staring them down will be a defense that has been the backbone of Nick Saban’s squad, keeping 
        the Tide in playoff contention all year despite hiccups on offense, and containing the Georgia 
        Bulldogs in the SEC Championship Game to earn
        {citation(4, "#", "'Earn' may be a controversial word to use here given the circumstances of Alabama’s selection.")}
        a spot in the semi-final. 
      </div>
      <h4><b>Rushing</b></h4>
      <div>
        Michigan has operated a run-first offense throughout the season, rushing on 59.6% of plays, 
        the 17th highest mark in the nation.
        {citation(5, "https://www.teamrankings.com/college-football/stat/rushing-play-pct", 
        "Note: all statistics are as of time of writing, and statistics may have changed with the completion of additional games.")}
        Their production has been more effective through the air, however: Michigan’s passing success rate
        {citation(6, "https://gameonpaper.com/cfb/glossary",
        "Success rate, here, refers to the percentage of plays that produce positive Expected Points Added. In many contexts, success rate is defined on a down-by-down basis, and is based on yards gained.")}
        of 54.2% is second best in college football, compared 
        to a 41.4% success rate on the ground that ranks just inside the top 60
        {citation(7, "https://gameonpaper.com/cfb/year/2023/team/130")}
        . Their Expected Points Added (EPA) per play, a metric that attempts to estimate how many points 
        a given play will contribute to a team’s final score, clocks in at .34 on passing plays 
        (fifth in the country) but just .01 (70th in the country) on rushing plays.
      </div>
      <br />
      <div>
        Alabama’s defense matches Michigan’s strengths. Against the pass, the Crimson Tide are just 
        outside the best twenty defenses in EPA/Play allowed, with a mark of -0.12 indicating the average 
        pass play against the Alabama defense ends up costing their opponent’s offense, not contributing to 
        it. Against the rush, Alabama is in the middle of the pack, giving up just barely above 0 EPA/Play, 
        sufficient for 59th best in FBS{citation(8, "https://gameonpaper.com/cfb/year/2023/team/333")}.
      </div>
      <br />
      <div>
      Alabama’s trouble with the run was best exploited by rival Auburn, who achieved success with a dual-threat 
      run game featuring frequent use of quarterback draws, read-options, and play-action passes to put Bama 
      defenders in conflict, all tactics that Michigan can replicate. Auburn’s QB run game centered around Payton 
      Thorne (a player many Wolverine fans may recognize from his prior stint at Michigan State) who, while 
      relatively athletic, grades out a fair bit below J.J. McCarthy as a rushing quarterback
      {citation(9, "https://www.nfldraftbuzz.com/stats/R/QB/1/2024")}
      . Auburn runs out of similar personnel packages as Michigan, often using 12 personnel (1 running back and 
      2 tight-ends), a look Michigan likes to use with TEs Colston Loveland and AJ Barner (and, on some run 
      plays, Max Bredeson). Incorporating Thorne into the run game with fake hand-offs and read-options paid 
      dividends for Auburn by freezing Alabama’s linebackers, who had to pick which player to follow.
      </div>
      <br />
      <div>A good example is this designed QB keeper, run early in the second quarter.</div>
      {video("https://www.youtube.com/embed/TSXoWWH-dr0?start=2252&end=2266&autoplay=1")}
      <div>
        The initial fake hand-off to running back Jarquez Hunter makes Alabama’s WLB Deontae Lawson 
        (number 32) bite inside, expecting to be needed filling a gap down the middle of the field. 
        While he recovers quickly once he realizes Thorne has kept the ball, his initial steps inside 
        are enough to open up the outside of the field, giving Auburn a good gain on first down.
      </div>
      <br />
      <div>Another notable play is this QB read late in the third quarter, which led to a big 15 yard gain.</div>
      {video("https://www.youtube.com/embed/TSXoWWH-dr0?start=5464&end=5475&autoplay=1")}
      <div>
        Here, Thorne has the option of handing the ball off to his running back or keeping it himself. 
        The offensive line moves too quickly to the second level, letting two Alabama defensive linemen 
        into the backfield completely unblocked. The threat of the hand-off to RB Hunter freezes them, 
        however, letting Thorne escape untouched and pick up a first down. Adding McCarthy’s athleticism 
        to this play may have yielded an even bigger gain, as he’s shown the ability to shake players in 
        the open field throughout the season.
        {citation(10, "https://youtu.be/nM5G-gIJJ7M?si=ptGk314LpMUgbHmx&t=215")}
      </div>
      <br />
      <div>
        If Michigan is able to use some of these concepts to force Alabama’s defenders into difficult decisions, 
        they should be able to create success running the ball, especially with the talented running trio of 
        McCarthy, RB Blake Corum, and RB Donovan Edwards. Using Edwards (and potentially incorporating receivers 
        like freshman Semaj Morgan) to attack the perimeter with the run game could add additional pressure 
        on Bama’s defenders in the run game.
        {citation(11, "#", "Michigan’s wide receivers have been lackluster in run-blocking, though, and Alabama’s corners and safeties impressed me against the run when watching film. Getting TEs outside to block and running some sort of reverse that comes to the backside would make this more likely to succeed.")}
      </div>
      <br />
      <div>
        On the other hand, if Michigan just tries bashing it down the middle of the field without any added spice, 
        their run game may stall out, producing more of the middling numbers they’ve had throughout the year. 
        Still, even on base run plays without much trickery, Auburn was frequently able to break off big runs 
        {citation(12, "https://youtu.be/TSXoWWH-dr0?si=7zMyNZrBX4hITzwr&t=3216")}
        running a gap running scheme similar to Michigan’s.
        {citation(13, "#", "Michigan’s preferred running play is Duo, which pulls a blocker to the playside and incorporates double teams at the line of scrimmage, then relies on the running back to coax a linebacker into the wrong gap to open up space.")}
      </div>
      <br />
      <div>
        Auburn also ran something similar to the “Orji package” Michigan has deployed
        {citation(14, "https://www.mlive.com/wolverines/2023/11/michigan-used-alex-orji-trick-play-to-beat-ohio-state-whats-next.html")}
        in their last two games (and which is likely on the table for the upcoming matchup), drawing up multiple
        {citation(15, "https://youtu.be/TSXoWWH-dr0?si=a6XHzEPLgAHhGWCF&t=4870")} plays {citation(16, "https://youtu.be/TSXoWWH-dr0?si=91kxmFMFkqpCSI8i&t=5881")}
        for their backup quarterback Robby Ashford, whose running ability is a bit more impressive than Thorne’s. 
        Neither was particularly effective, however, with Alabama’s linebackers stuffing one after just a few 
        yards, and Bama freshman safety Caleb Downs {citation(17, "Stud.")} shedding two blockers to blow up the second in the backfield.
      </div>
      <h4><b>Passing</b></h4>
      <div>
        If Michigan is going to continue to forefront the run against Alabama, they can use that to the 
        advantage of the passing game by employing play action. Michigan has used play action on 23.1% of 
        J.J. McCarthy’s pass attempts this season. When doing so, they have been successful. McCarthy is 
        passing at 78.8% completion for 11.1 yards/attempt on play action passes, compared to 74% completion 
        for 9.1 yards/attempt overall. {citation(18, "  https://mgoblue.com/news/2023/12/25/football-michigan-monday-rose-bowl-vs-alabama")}
        Dialing up play action passes more frequently in the Rose Bowl could lend support to both the passing 
        and rushing attacks, forcing Bama’s ‘backers to hesitate when deciding whether to drop into their zones.
      </div>
      <br />
      <div>
        In the SEC Championship Game, Georgia used play action against Alabama to relatively consistent success, 
        including this dump off to TE Brock Bowers on their first play from scrimmage.
      </div>
      {video("https://www.youtube.com/embed/0PvgkTQBAGU?start=623&end=655&autoplay=1")}
      <div>
        Here, WLB Jihaad Campbell (number 30, the linebacker lined up closer to the top of the screen) 
        bites on the initial fake hand-off as Bowers slips out into the flat uncovered, creating an easy 
        completion with around 20 yards after the catch.
        {citation(19, "#", "I noticed both Campbell and Lawson biting on fake hand-offs consistently. They recovered quickly, but just a second of hesitation or one or two steps in the wrong direction can give an extra 4-5 yards.")}
      </div>
      <br />
      <div>
        Play action should fit well into a Michigan passing game plan that will likely center around 
        finding receivers (especially tight ends) in the middle of the field for chunk gains. While WR 
        Roman Wilson was the star of the receiving game early in the season, he has since been surpassed 
        in catches and targets by WR Cornelius Johnson and TE Colston Loveland as Michigan has shifted from 
        focusing on Wilson’s speed on deep posts and fly routes to working shorter crossing routes that take 
        advantage of their tight ends’ size and Johnson’s hands.
      </div>
      <br />
      <div>
        Expect this to continue against Alabama. The Crimson Tide’s defense operates out of a 4-2 front that 
        uses zone almost exclusively. With NFL-caliber talent in pretty much every defensive back
        {citation(20, "#", "Including their backups. After Kool-Aid McKinstry left the SECCG with an injury, backup Trey Amos was absolutely lockdown as his replacement.")}
        , Michigan will find its best match-ups in the center of the field, where Johnson, Loveland, 
        and Barner can expect to find themselves covered by “star” safety
        {citation(21, "#", "Star is a signature Saban position, a lot like a nickelback.")}
        Malachi Moore, middle linebacker Trezmen Marshall, and weak linebackers Lawson and Campbell
        {citation(22, "#", "In the games I watched, Jack LB Chris Braswell occasionally dropped into coverage (and when he did, he was often left in the dust), but he’s primarily a pass rusher (and a very good one at that).")}
        . Make no mistake, these are all solid defenders, but Loveland’s size, Johnson’s speed, and both 
        of their catching abilities will create openings for McCarthy if he can fit the ball into them.
        {citation(23, "https://youtu.be/0PvgkTQBAGU?si=vsGxVW0zlCj2Q4YL&t=5559",
        "Good example of such a window in the SECCG, where Beck throws a beautiful ball into a tightly covered receiver. If JJ can make these throws, Mich will be in business.")}
      </div>
      <br />
      <div>
        Michigan can also use pre-snap motion to isolate the matchups they want. Alabama’s commitment to zone 
        coverage creates the opportunity to move receivers after Alabama has revealed their defensive alignment, 
        creating mismatches that target weaknesses in the secondary
        {citation(24, "#", "In the Auburn and Georgia games, an Alabama defender followed a player in pre-snap motion only a single time, with players switching coverages in all other instances.")}
        . Georgia did this to great effect in the SEC Championship Game, creating their largest play of the game.
      </div>
      {video("https://www.youtube.com/embed/0PvgkTQBAGU?start=4558&end=4596&autoplay=1")}
      <div>
        Here, Bowers (number 19) goes in motion, coming from his position just off the offensive line to a route 
        stem in the slot, next to receiver Arian Smith. When he does so, STAR Malachi Moore drops down to take 
        over a zone in the flat, and CB Kool-Aid McKinstry (arguably the best corner in college football), who 
        initially would have been following Smith on his deep post route, takes responsibility for the middle 
        of the field, where Bowers runs his crosser. This leaves safety Jaylen Key alone covering the post, whom 
        Smith leaves in the dust. Meanwhile, Jihaad Campbell ends up covering grass, creating two good matchups 
        for Georgia QB Carson Beck to choose from (the post and the crossing route).
      </div>
      <br />
      <div>
        Michigan has had success with similar motion concepts themselves. Early in their game against Purdue, 
        for example, Michigan motioned out Cornelius Johnson from his initial alignment inside to the outside 
        receiver position. Doing so turned Roman Wilson into the slot receiver, allowing him to take advantage 
        of his man defender’s initial outside leverage to cut inside on a post with acres of separation, and 
        manipulating the single high safety into covering Johnson’s route instead of Wilson’s.
      </div>
      {video("https://www.youtube.com/embed/09BuRoNDOEs?start=50&end=60&autoplay=1")}
      <div>
        While the short-to-mid range passing game will likely be the centerpiece of Michigan’s passing strategy, 
        taking advantage of pre-snap motion should enable them to hit one or two deep balls to Wilson, who may 
        have an otherwise quiet day.
      </div>
      <br />
      <div>
        That said, the passing game could well face difficulty because of Alabama’s edge rushers. Michigan’s 
        offensive line has looked spotty in pass protection since the Penn State game, and the injury to 
        guard Zak Zinter {citation(25, "https://www.foxsports.com/stories/college-football/michigan-ol-zak-zinter-undergoes-surgery-after-suffering-broken-tibia-fibula")}
        will only add to the difficulty. Michigan is 51st in the country in allowed sack percentage, 
        with the pocket passer going down on 5.4% of dropbacks
        {citation(26, "https://www.teamrankings.com/college-football/stat/qb-sacked-pct")}
        . The Crimson Tide’s edge pass rush is nothing short of elite, led by SLB Dallas Turner (projected 
        to be the first edge defender taken in the draft) and complemented by JACK Chris Braswell (also a 
        likely first or second rounder). The Tide are 7th in the country in sack percentage, taking down 
        opposing quarterbacks on a whopping 9.7% of dropbacks
        {citation(27, "https://www.teamrankings.com/college-football/stat/sack-pct")}
        . McCarthy may well feel heat from the outside, and his ability to escape the pocket and extend 
        plays when coverage breaks down will be critical to a successful Michigan offensive attack.
      </div>
      <h4><b>Outlook</b></h4>
      <div>
        Overall, expect Michigan to be relatively successful moving the football, provided they run a 
        balanced offense and are able to take advantage of Alabama’s linebackers with pre-snap motion 
        and frequent play action {citation(28, "#", "Also, expect at least one of a flea flicker, a double reverse, or a halfback pass, because this is Michigan.")}
        . Michigan probably won’t hit their season-average 37 points
        {citation(29, "https://www.teamrankings.com/college-football/stat/points-per-game")}
        , and some drives will sputter out, but putting up 27-30 points seems doable, and that would 
        likely be enough to carry the Wolverines into the national championship game.
      </div>
      <br />
      <div>Go Blue.</div>
    </div>
  )
}