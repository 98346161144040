import React from "react";

export default function Header() {
  return (
    <div className="projects-header">
      <h1>Wolverine Sports Analytics Projects</h1>
      <h4>Click on a project for more details</h4>
    </div>
  );
}
